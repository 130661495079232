@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@450;700&display=swap');

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1; /* Hace que el contenido principal ocupe todo el espacio disponible, empujando el footer hacia abajo */
}

body{
  /* background-color: var(--primary-color);  */
  /* background: linear-gradient( #212529, rgb(95, 95, 95)); */
  font-family: 'Montserrat', monospace;
  /* background-image: url('../public/images/FondoOscuro.jpg'); */
  /* background-image: url('../public/images/FondoOscuro.jpg'); */
  background-image: url('../public/images/fondo_5.jpg');
  background-size: cover; 
  background-repeat: no-repeat;  
  background-position: center center; 
  backdrop-filter: brightness(30%);
  background-attachment: fixed;

  /* box-shadow: inset 0 0 0 10000px rgba(0,0,0,.3); */
  

   /* Opcion 2 */ 
   /* background: linear-gradient(-45deg, #000000, #202020, #202020, #000000);
   background-size: 400% 400%;
   animation: gradient 5s ease infinite; */
}

.noOverflow{
    overflow: hidden;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--primary-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .App-link {
  color: #61dafb;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*----------------------------------------------------------------*/



/* ---------------------- ALPINIST IMAGE --------------------*/
.contact-us-container img {
  width: 200px;  
  border-radius: 8px;
  margin: 10px 0;
  /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); */
}

/* ---------------------- NAVBAR CSS------------------------------- */
.user-avatar-btn {
  background-color: var(--primary-color);
  border-radius: 50%;           /* Hace que sea circular */
  width: 50px;                  /* Ancho del botón */
  height: 50px;                 /* Alto del botón */
  display: inline-block;
  overflow: hidden;             /* Esto es para que la imagen se recorte si es mayor que el botón */
  position: relative;
  line-height: 0px;            /* Centra verticalmente la imagen/ícono en el botón, antes era 50 */
  text-align: center;           /* Centra horizontalmente la imagen/ícono en el botón */
}

.user-avatar-btn img {
  width: 100%;                  /* Asegura que la imagen cubra todo el botón */
  vertical-align: middle;       /* Alinea verticalmente la imagen en el medio */
}

.navbar-logo {
  height: 40px;
  margin-left: 14vw;
  transition: all 0.3s ease;
}

/* @media (max-width: 700px) {
	.navbar-logo{
    margin-left: 10px;
    height: 50px;
  }
  } */



.navbar-collapse-right {
  justify-content: flex-end; 
}

.nav-item{
  font-size: 15px;
 padding-left: 10px;
 padding-right: 10px;
}

.navbar{ /* Color de fondo del navbar*/
  /* background-color: var(--primary-color); */
  /* background-color: transparent; */
background-color: var(--primary-color-gradient);
position: sticky;
backdrop-filter: blur(200px);
padding-right: 14vw;

}

@media (max-width: 1000px) {
	.navbar {
    padding-right: 0vw;
	}

}


.navbar-dark .navbar-nav .nav-item .nav-link {
  transition: transform 0.3s; 
}

.navbar-dark .navbar-nav .nav-item .nav-link:hover {
  transform: scale(1.2);  
  color: white;
}

.navbar-dark .navbar-nav .nav-item .nav-link:active {
  color: #f0f0f0; /* Color ligeramente diferente al hacer clic */
}

.navbar-dark .navbar-nav .nav-item .nav-link.active {
  color: white; /* Color blanco */
  font-weight: normal; /* Elimina cualquier cambio de estilo al estar activo */
}

.nav-link {
  padding: 10px 15px; /* Ejemplo de padding */
  transition: transform 0.3s ease;
  
}

.nav-link.active {
  padding: 10px 20px;
  /* transform: scale(1.2);  */
  /* color: var(--tertiary-color) !important; */
}




@keyframes navbar_item_hover {
  from {
    transform: scale(1);  /* Tamaño inicial (100%) */
  }
  to {
    transform: scale(1.1);  /* Tamaño final (110%) */
  }
}



/* ---------------------- NAVBAR CSS ------------------------------- */

/* ---------------------- LANDING PAGE CSS ------------------------------- */
.title-up-landing {
 
  color: transparent; 
  font-size: 80px;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
  transition: all 0.3s;
  white-space: nowrap; 
  overflow: hidden; 
  border-right: 2px solid #ffffff; 
  width: 0; 
  animation: typing 1.5s steps(14, end) forwards, blink 0.9s step-end infinite; 
  transition: font-size 0.3s ease; 
}

@media (max-width: 700px) {
	.title-up-landing{
    font-size: 40px !important
  }
  }

/* Animación de escritura */
@keyframes typing {
  from {
      width: 0;
      color: #ffffff; 
  }
  to {
      width: 100%;
      color: #ffffff; 
  }
}

/* Animación de cursor parpadeante */
@keyframes blink {
  from, to {
      border-color: transparent;
  }
  50% {
      border-color: #ffffff;
  }
}

.subtitle-up-landing {
  font-family: 'Caveat'; /* Usamos la fuente que parece escrita a mano */
  font-size: 70px;
  color: transparent; /* Inicialmente transparente para que no se vea */
  animation: slideUp 1s forwards 1.5s, writeEffect 0s forwards; /* Dos animaciones: una para deslizar hacia arriba y otra para el efecto de escritura */
  position: relative;
  bottom: -50px; /* Posición inicial desde abajo */
}

/* Animación para deslizar hacia arriba */
@keyframes slideUp {
  to {
      bottom: 0;
      color: #ffffff; 
  }
}

/* Animación para el efecto de escritura */
@keyframes writeEffect {
  from {
      width: 100%;
  }
  to {
      width: 100%;
  }
}


.card-header-landing {
  border-bottom: 1px solid transparent; 
  background-color: transparent;
}

.container-dashboard{
max-width: 98%; 
  margin: 0 auto;
}

/* ---------------------- LANDING PAGE CSS ------------------------------- */


.header-container {
  position: absolute;
  display: flex;
  height: 100%;
  /* z-index: 10; */
}

.logo {
  position: absolute;
  height: 400px;
  /* z-index: 10; */
  /* Otros estilos para el logo aquí */
}


/* ---------------------- TABS CSS ------------------------------- */
.tabsContainer{
  /* background-color: #00aced; */
  display: flex;
  align-items: start;
  background-color: transparent;
  padding-top: 100px;
  height: 84vh;
  /* padding-bottom: 350px; */
  /* z-index: 0; */
  /* margin-left: 14vw; */
}



@media (max-width: 1000px) {
	.tabsContainer {
    align-items: center;
		flex-direction: column;
    margin-left: 0vw;
	}
}

.tabsContainer img {
  width: 300px;  
  border-radius: 8px;
  margin: 10px 0;
  /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); */
}

.tabsContainer h2 {
color: #ffffff;
font-size: 60px;
}

/* ---------------------- TABS CSS ------------------------------- */

