
/*Variables de colores que estoy usando*/
:root {
  --primary-color: #212529;
  --secondary-color: #e74c3c;
  --tertiary-color: #00ff00;
  --primary-color-gradient: #080d11b4;
  --bg-color-gradient: #163e5e;
  --f-size: 100;
  --f-unit: 1vmin;
  --f: calc(var(--f-size) * var(--f-unit));
  --bg: #181717; 
}


body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  min-height: 100%;
}



