.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    /* font-family: 'Arial', sans-serif; */
    background-color: transparent
}

.not-found-title {
    font-size: 8em;
    margin: 0;
    color: #ffffff;
}

.not-found-text {
    color: #ffffff;
    font-size: 1.2em;
}

.image-container {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 20px 0;
}

.web-icon,
.mobile-icon,
.software-icon {
    width: 60px;
    height: 60px;
    background-size: cover;
}

.web-icon {
    background-image: url('../../public/images/myLogo.png');
}

.mobile-icon {
    background-image: url('../../public/images/ficha_logo.png');
}

.software-icon {
    background-image: url('../../public/images/va_logo.png');
}

.not-found-link {
    color: #007BFF;
    text-decoration: none;
}

.not-found-link:hover {
    text-decoration: underline;
}
