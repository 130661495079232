.contactusBox {
  padding-top: 15vh;
  /* background-color: #007bff; */
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 200px;
}

.contactusInfo {
  color: #ffffff;
  flex: 1;
  margin-left: 14vw;
  text-align: left;
  font-size: 20px;
  margin-right: 100px;
}

.contactusItem {
  margin-bottom: 15px;
  /* Espacio entre cada ítem */
}

.contactusItem i {
  margin-right: 15px;
}

.contactusLabel {
  font-weight: bold;
  margin-right: 5px;
}

.contactusItem p {
  margin-top: 5px;
  /* Espacio entre el subtítulo y el detalle */
}

.mapContainer {
  width: 30vw;
  /* Ajusta el ancho según necesites */
  max-width: 600px;
  /* min-width: 300px; */
  border-radius: 10px;
  /* Bordes redondeados */
  /* Otros estilos que puedas necesitar */
}

/* Estilos para la columna del formulario */
.contactusForm {
  flex: 1;
}

@media (max-width: 1000px) {
  .contactusBox {
    flex-direction: column;
  }

  .contactusInfo {
    margin-right: 0;
    margin-bottom: 20px;
    margin-left: 0vw;
    margin-right: 0px;
		width: 70vw;
		display: flex;
		flex-direction: column;
		align-items: start;
  }

	.mapContainer{
		width: 100%;
	}
}

/* Estilos para el formulario */
.contactusForm form {
  display: flex;
  flex-direction: column;
}

/* Estilos para los campos del formulario */
.form-group {
  margin-bottom: 10px;
}

/*Actualmente no esta en uso*/
/* Estilos para el botón del formulario */
button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-weight: bold;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.custom-swal-popup {
  color: #ffffff;
  border-radius: 10px;
}

.custom-swal-confirm-button {
  background-color: #283e50;
  color: white;
  border: none;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
  width: 70px;
}
