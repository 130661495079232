/* Estilos generales para el contenedor "About Us" */
.aboutusContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0); /* Fondo transparente con efecto borroso */
    backdrop-filter: blur(10px); /* Efecto de desenfoque */
}

/* Estilos para la imagen del equipo de trabajo */
.aboutusImage img {
    max-width: 100%;
	width: 400px;
    height: auto;
    border-radius: 10px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
}

/* Estilos para el contenedor de texto */
.aboutusTextContainer {
    flex: 1;
    margin-left: 20px;
}

/* Estilos para el texto */
.aboutusText {
    font-size: 16px;
    line-height: 1.6;
    color: #d8d8d8;
	text-align: justify;
}

.aboutusBox {
    /* Estilos existentes para el centrado */
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 916px;
    /* padding-bottom: 13vw;
    padding-top: 13vw; */
    /* height: 50vw; */
    position: relative;
    /* Importante para posicionar correctamente el pseudo-elemento */

    /* Eliminar clip-path de aquí */
}

@media (max-width: 1000px) {
	.aboutusBox {
		align-items: center;
	}

}

.aboutusBox::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(../../public/images/bgCodeCerro2.jpg);
    background-size: cover;
        /* clip-path: polygon(0% calc(25% - 1vw),
                    100% 0%,
                    100% calc(75% - 1vw),
                    0% 100%); */
    clip-path: path("M0 257L1920 0V659L0 916V257Z");
    z-index: -1;
    /* Asegura que el fondo quede detrás del contenido */
}