.services-section {
  text-align: center;
  color: #fff;
  /* margin: 50px; */
  padding-top: 100px;
}

.services-title {
  margin-bottom: 2rem;
  font-size: 2rem;
  color: var(--Blanco, #fff);
  text-align: center;
  text-shadow: 0px 10px 50px #246bad, 0px 10px 30px #246bad;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.services-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 20px;
}

.service {
  padding: 20px;
  /* background: rgba(255, 255, 255, 0.1); */
  border-radius: 5px;
  max-width: 500px;
  margin: 0 auto;
}

.service h3 {
  color: var(--Blanco, #fff);
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  margin-top: 20px;
}

.service p {
  color: var(--Blanco, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.icon {
  font-size: 2rem;
  margin-bottom: 1rem;
}

/* Para pantallas medianas, si queremos que el último servicio ocupe una columna completa */
@media (min-width: 1000px) {
  .services-container {
    grid-template-rows: auto auto 1fr;
  }
  .services-container .service:last-child {
    grid-column: span 2;
  }
}

/* Para pantallas pequeñas, todos los servicios en una sola columna */
@media (max-width: 1000px) {
  .services-container {
    grid-template-columns: 1fr;
  }
}
