.neon-effect {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.neon-effect::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 15%; /* Ajusta este valor para cambiar la altura del efecto neón */
  /* background: rgba(36, 107, 173, 0.74); */
  box-shadow: 0px 220px 250px rgba(36, 107, 173, 0.75), 0px 220px 250px rgba(36, 107, 173, 0.75), 0px 220px 250px rgba(36, 107, 173, 0.75);
  filter: blur(100px); /* Ajusta el radio de desenfoque */
  z-index: -3;
}
