.section-signup {
  /* fondo de la pantalla */
  padding: 50px 0;
  background-color: #212529;
  /* background: radial-gradient(circle at center, #66ff33, #212529); */
  /* background: linear-gradient(#212529, rgb(95, 95, 95)); */
}

.container {
  max-width: 500px;
  margin: 0 auto;
}

.card-body {
  padding-top: 20px;
  border-bottom: 1px solid #f80000;
}

.card-signup {
  /* Card del from*/
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 40px 80px rgb(0, 0, 0); */
  background-color: rgb(95, 95, 95);
}
.text-center {
  display: inline-block;
  padding-bottom: 4rem;
}

.social-buttons-container {
  border-bottom: 1px solid #ffffff;
  background-color: transparent;
  padding-bottom: 20px;
}

.card-header {
  border-bottom: 1px solid transparent;
  background-color: transparent;
}

.input-group-text {
  background-color: #212529;
}

.form-control {
  color: white;
  /* background-color: rgb(255, 255, 255); */
  background-color: transparent;
  border-bottom-width: 2px;
  /* border-top-color: #ffffff;
	border-left-color: #ffffff;
	border-right-color: #ffffff; */

  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #ffffff;
  margin-right: 50px;
  margin-left: 50px;
}

.form-control:focus {
  background-color: transparent;
  color: white;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #80bdff;
  box-shadow: none
}


.form-control-message {
  color: white;
  /* background-color: rgb(255, 255, 255); */
  background-color: transparent;
  border: 4px solid #ffffff; /* Un borde sutil */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra ligera */
  margin-right: 50px;
  margin-left: 50px;
  width: calc(100% - 100px); /* Ajuste del ancho */
  display: block;
  padding: 12px; /* Espacio interno para el texto */
  font-size: 1rem; /* Tamaño de la fuente */
  font-family: inherit; /* Hereda la tipografía del formulario */
  border-radius: 15px; /* Bordes ligeramente redondeados */
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out; /* Transición suave */
}

.form-control-message:focus {
  color: white;
  border-color: #80bdff; /* Color del borde al enfocar */
  outline: 0; /* Elimina el contorno predeterminado */
  box-shadow: 0 0 0 0.2rem rgba(37, 76, 118, 0.25); /* Sombra externa al enfocar */
}

/* Estilo base para todos los botones */
.social-line .btn {
  margin: 0 20px;
  background-color: #6c757d;
  color: white;
  border-radius: 50%;
  transition: transform 0.3s, color 0.3s;
}

/* Estilo hover para cada botón */
.social-line .btn:hover {
  transform: scale(1.5);
}

.social-line .btn-facebook:hover {
  color: #3b5998; /*Facebook */
}

.social-line .btn-twitter:hover {
  color: #00aced; /*Twitter */
}

.social-line .btn-google:hover {
  color: #dd4b39; /*Google */
}

/* Inputs */
.floating-label {
  position: relative;
}

.floating-label input {
  box-shadow: none;
}

.floating-label label {
  position: absolute;
  top: 50%;
  left: 50%; /* Centrar horizontalmente */
  transform: translate(-50%, -50%); /* Ajuste fino para centrar exactamente */
  font-size: 1rem;
  color: #ffffff;
  transition: all 0.3s;
  pointer-events: none;
  background-color: transparent;
}

.floating-label input:not(:placeholder-shown) + label,
.floating-label input:focus + label {
  top: -0.7rem;
  left: 4.5rem;
  font-size: 1rem;
  color: #ffffff;
  background-color: transparent;
  font-weight: 900;
}

.card-footer {
  display: flex;
  padding-top: 20px;
  background-color: transparent;
  align-items: center;
  justify-content: center;
}

.btn-neutral.btn-round.btn-lg {
  background-color: #283e50;
  color: white;
  border: none;
  border-radius: 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.btn-neutral.btn-round.btn-lg:hover {
  background-color: #5988b0;
}
.btn-neutral.btn-round.btn-lg:active {
  transform: scale(0.95);
  background-color: #15212b;
}
