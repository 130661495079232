.mac-window {
    border-radius: 20px;
    box-shadow: 0px 0px 200px 0px #246bad84;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    width: 80vw;
    margin-left: 15vw;
    margin-top: 115px;
    max-width: 445px;
    min-width: 300px;
    color: #ffffff;
    /* box-sizing: border-box; */
}

.mac-window-contact {
    border-radius: 20px;
    box-shadow: 0px 0px 200px 0px #246bad84;
    background-color: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(5px);
    width: 80vw;
    margin-right: 10vw;
    max-width: 613px;
    min-width: 300px;
    /* box-sizing: border-box; */
}



.title-bar {
    background-color: #283E50;
    color: white;
    padding: 8px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-weight: normal;
    text-align: center;
    font-size: 20px;
}

.content {
    padding: 0px;
    display: flex;
    flex-direction: column;
}

.aboutus-text{ 
    text-align: justify;
    line-height: 20px;
    font-size: 16px;
    padding-left: 50px;
    padding-bottom: 40px;
    padding-right: 50px;
    padding-top: 0px;
}

.window-controls {
    position: absolute;
    top: 8px;
    left: 8px;
    display: flex;
    align-items: center;
}

.control {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 5px;
    margin-top: 10px;
    margin-left: 5px;
}

.control.close {
    background-color: #ff605c;
}

.control.minimize {
    background-color: #ffbd44;
}

.control.maximize {
    background-color: #00ca56;
}

.title-bar {
    position: relative;
    /* Resto de estilos... */
}

.aboutus-write-animation {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    align-items: center;

    display: inline-block;
    /* Cambia de block a inline-block */
    font-weight: bolder;
    font-size: 40px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    height: 49px;
    border-right: 7px solid #ffffff;
    width: auto;
    /* Cambia de 0 a auto */
    animation: typing-aboutus 1.5s steps(15, end) forwards, blink-macwindows 0.9s step-end infinite;
}

/* Animación de escritura */
@keyframes typing-aboutus {
    from {
        width: 0;
    }

    to {
        width: 290px;
    }
}

/* Animación de cursor parpadeante */
@keyframes blink-macwindows {

    from,
    to {
        border-color: transparent;
    }

    50% {
        border-color: #ffffff;
    }
}

.contactus-write-animation {
    display: inline-block;
    /* Cambia de block a inline-block */
    font-weight: bolder;
    font-size: 40px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    border-right: 7px solid #ffffff;
    width: auto;
    /* Cambia de 0 a auto */
    animation: typing-contactus 1.5s steps(13, end) forwards, blink-macwindows 0.9s step-end infinite;
}

/* Animación de escritura */
@keyframes typing-contactus {
    from {
        width: 0;
    }

    to {
        width: 260px;
    }
}

@media (max-width: 1000px) {
	.mac-window-contact {
		margin-right: 0vw !important
	}

	.mac-window {
        margin-left: 0vw !important;
	}
    .aboutus-write-animation {
        font-size: 30px;
        animation: typing-aboutus-small 1.5s steps(13, end) forwards, blink-macwindows 0.9s step-end infinite;
    }

    .contactus-write-animation {
        font-size: 30px;
        animation: typing-contactus-small 1.5s steps(13, end) forwards, blink-macwindows 0.9s step-end infinite;
    }

    @keyframes typing-aboutus-small {
        from {
            width: 0;
        }
        to {
            width: 220px; /* Ajustar según la necesidad */
        }
    }

        @keyframes typing-contactus-small {
        from {
            width: 0;
        }
        to {
            width: 200px; /* Ajustar según la necesidad */
        }
    }
    
}
