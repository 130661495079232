.custom-alert {
  background-color: #283e50;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-color: transparent;
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 66px;
}

.custom-alert-text {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 14px;
  padding-left: 13px;
}

.btn-close {
  padding-top: 13px !important;
  padding-left: 11.8px !important;
  padding-bottom: 13px !important;
  width: 14px !important;
  height: 14px !important;
  color: white !important;
}

.btn-close:before {
  color: white !important;
}

.custom-alert-icon {
  font-size: 20px;
}

/* .alert-info {
    color: #055160;
    background-color: #cff4fc;
    border-color: #b6effb;
} */

