.hero-section {
  /* background: url('path-to-your-background-image.jpg') no-repeat center center; */
  background-size: cover;
  color: white;
  text-align: center;
  /* padding: 50px 20px; */
  margin-left: 14vw;
  z-index: 1;
  position: absolute;
  width: 600px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .hero-section {
    align-items: center;
    flex-direction: column;
    margin-left: 0vw;
    width: 400px;
    justify-content: center;
    margin-left: 0px;
  }
}

.hero-section h1 {
  color: var(--Blanco, #fff);
  text-align: center;
  text-shadow: 0px 10px 50px #246bad, 0px 10px 30px #246bad,
    0px 10px 30px #246bad, 0px 10px 30px #246bad, 0px 10px 30px #246bad;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hero-section h2 {
  font-size: 2em;
  /* margin-bottom: 1em; */
  font-weight: lighter;
}

.hero-section p {
  font-size: 1em;
  margin-bottom: 1.5em;
}

.services-button {
  display: flex;
  width: 187px;
  height: 50px;
  padding: 7px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  border: 0.5px solid #fff;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 50px 0px rgba(255, 255, 255, 0.15) inset;
  backdrop-filter: blur(5px);
  color: var(--Blanco, #fff);
  text-align: center;

  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.services-button-link {
  text-decoration: none;
  color: inherit;
}

.services-button:hover {
  background-color: #66788a; /* Darker shade for hover effect */
  border: 3px solid #fff;
  box-shadow: 0px 0px 10px 0px #246bad;
}
