/* ---------------------- FOOTER CSS ------------------------------- */

/* .footerBox {
	padding: 80px 60px;
	background-color: transparent;
	
	width: 100%;
  } */
  
  /* .footerh1{
	color: var(--tertiary-color);
	text-align: center;
	margin-top: -50px;
  } */
  
/*   
  @media (max-width: 1000px) {
	.footerBox{
	  padding: 80px 60px;
	  background-color: transparent;
	  
	  width: 100%;
  }
  }
   */
  /* .footerContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 1000px;
	margin: 0 auto;
  }
  
  .footerColumn {
	display: flex;
	flex-direction: column;
	text-align: left;
	margin-left: 60px;
  }
   */
  /* .footerRow {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
	grid-gap: 20px;
  } */
/*   
  @media (max-width: 1000px) {
	.footerRow {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
  } */
  
  
  /* .footerLink {
	color: #fff;
	margin-bottom: 20px;
	font-size: 18px;
	text-decoration: none;
  } */
  
  /* .footerLink:hover {
	color: var(--tertiary-color);
	transition: 200ms ease-in;
  } */
  
  /* .footerHeading {
	font-size: 24px;
	color: #fff;
	margin-bottom: 40px;
	font-weight: bold;
  } */
  
  /* ---------------------- FOOTER CSS ------------------------------- */

  .footer2Container {
	/* background-color: transparent; */
	/* background-color: rgb(46, 63, 81); */
	/* display: flex; */
	flex-direction: column;
	align-items: center;
  }
  
  .footerhr {
	border-bottom: 5px solid rgb(255, 255, 255);
	width: 100%; 
	margin-top: 30px; 
	margin-left: 20px;
	margin-right: 20px;
	opacity: 100%;
  }

  
  
  .footer2Row {
	display: flex;
	justify-content: center; 
	padding-top: 10px;
	padding-bottom: 10px;
	overflow: hidden;
	align-items: center;
  }

	.footer-imagesL{
		/* width: 25vw */
		/* width: 5px;*/
		height: 25px; 
		object-fit: cover;
		object-position: right center;
	}

		.footer-imagesR{
		/* width: 25vw */
		/* width: 10vw; */
		height: 25px;
		object-fit: cover;
		object-position: left center;
	}
  
  
  
  .footerColumn2 {
	/* flex: 33.33%; */
	color: #ffffff;

  }

  .footer2Icons {
	padding-left: 20px;
	padding-right: 20px;
  }

  .iconProperties {
	fill: #ff0000; 
	width: 24px; 
	height: 24px;
}

.footer2logoImage{
	height: 100px;
	width: 100px; 
  }
  
  .footer2copy {
	font-size: 12px; 
	font-weight: bold; 
	color: #9c9c9c;
	
	letter-spacing: 2px;
	margin-top: 10px; 
  }

  /* Estilo para los hipervínculos en el pie de página */
.footer2-link {
	text-decoration: none;
	color: #ffffff;
	font-weight: bold;
	transition: color 0.3s;
	padding-left: 10px;
	padding-right: 10px;
  }
  
  .footer2-link:hover {
	color: var(--tertiary-color);
  }
  
  
  